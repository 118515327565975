import {
  Box,

  // Select,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonCircle,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiMessageAltDetail } from "react-icons/bi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_BASE_URL } from "../utils/constants";
import { getTokenFromLocal } from "../utils/utils";
import { AddNewAddressFlow } from "./AddNewAddressFlow";
import { Conversation } from "./Conversation";
import { PageTitle } from "./PageTitle";
import { SenderRow } from "./SenderRow";
import { UserHeader } from "./UserHeader";
import { Client } from "../services/server";

export const SenderList = () => {
  const [showFlow, setShowFlow] = useState<any>(false);

  const [senders, setSenders] = useState<any>([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { address } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const startCreateFlow = searchParams.get("create");

  const deleteConversationWrapper = (address: string) => {
    Client.deleteConversation(address, (response: any) => {
      console.log("deleteConversation");
      response.text().then((parsed: any) => {
        console.log("deleteConversation navigate");
        navigate("/feeds");
      });
    });
  };

  const getRecentsWrapper = () => {
    Client.getRecents((response: any) => {
      response.json().then((parsed: any) => {
        let items = parsed.map((data: any) => {
          let [address, name, unreadCount, lastSendTime] = data;
          name = name ? name : address.slice(0, 10) + "...";
          const storedTime = lastSendTime;
          // fix bad times
          if (!storedTime) {
            return {
              address, //: data[0],
              name, //: data[1],
              unreadCount, //: data[2],
              lastSendTime: `waiting...`,
            };
          } else {
            const mydate = new Date(storedTime);
            const dateStr = mydate.toLocaleDateString();
            const withPmAm = mydate.toLocaleTimeString("en-US", {
              // en-US can be set to 'default' to use user's browser settings
              hour: "2-digit",
              minute: "2-digit",
            });
            return {
              address,
              name,
              unreadCount,
              lastSendTime: `${dateStr} ${withPmAm}`,
            };
          }
        });
        setSenders(items);
      });
    });
  };

  // const delayedFetch = () => {
  //   setTimeout(() => {
  //     getRecentsWrapper();
  //     delayedFetch();
  //   }, 5000);
  // };

  useEffect(() => {
    getRecentsWrapper();
    // delayedFetch();
  }, []);

  const now = new Date();
  const dateStr = now.toLocaleDateString();
  const withPmAm = now.toLocaleTimeString("en-US", {
    // en-US can be set to 'default' to use user's browser settings
    hour: "2-digit",
    minute: "2-digit",
  });

  useEffect(() => {
    if (address) {
      onOpen();
    }
  });
  useEffect(() => {
    if (startCreateFlow) {
      onClose();
      setShowFlow(true);
    }
  }, [startCreateFlow]);

  return (
    <Stack minW="60vw" maxW={600}>
      <PageTitle title={"Feeds"} icon={<BiMessageAltDetail />} />
      <Box p="5" borderWidth="1px">
        {senders.length == 0 && (
          <>
            {[0, 1, 2].map((sender: any, index: number) => {
              return (
                <Box
                  key={`blanksender${index}`}
                  width="100%"
                  height="72px"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  padding="10px"
                  margin="3px"
                >
                  <HStack>
                    <SkeletonCircle size="40px" />

                    <Stack style={{ flexGrow: 1, width: 400 }}>
                      <Skeleton isLoaded={false}>
                        <div
                          style={{
                            width: "max-content",
                          }}
                        >
                          {sender}
                        </div>
                      </Skeleton>
                      <Skeleton isLoaded={false}>
                        <div
                          style={{
                            width: "max-content",
                            height: "8px",
                          }}
                        >
                          {sender}
                        </div>
                      </Skeleton>
                    </Stack>
                  </HStack>
                </Box>
              );
            })}
          </>
        )}

        <Modal
          size="xl"
          // onClose={onClose}
          onClose={() => {
            onClose();
            navigate(`/feeds`);
          }}
          isOpen={isOpen}
          isCentered
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <UserHeader
                name={(function () {
                  let sender = senders.filter(
                    (val: any) => val.address == address
                  );
                  if (sender.length > 0) {
                    sender = sender[0];
                  }

                  return sender.name;
                })()}
                address={address}
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Conversation address={address} />
            </ModalBody>
          </ModalContent>
        </Modal>

        {senders.map((sender: any, index: number) => {
          return (
            <SenderRow
              key={`sender-row-${index}`}
              index={index}
              sender={sender}
              deleteConversation={deleteConversationWrapper}
              getRecents={getRecentsWrapper}
            />
          );
        })}
      </Box>

      <br />

      <AddNewAddressFlow
        startWithFlowOpen={startCreateFlow && startCreateFlow}
      />
    </Stack>
  );
};
