import {
  Box,

  // Select,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../utils/constants";
import { getTokenFromLocal, makeid } from "../utils/utils";
import { DateRow } from "./DateRow";
import { InfoRow } from "./InfoRow";
import { Message } from "./Message";
import { Client } from "../services/server";

export const Conversation = ({ address }: any) => {
  const navigate = useNavigate();

  const [messages, setMessages] = useState<any[]>([]);
  const [messagesWithTime, setMessagesWithTime] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [keepFetching, setKeepFetching] = useState<boolean>(true);

  const enhanceData = () => {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 10);

    const fromApi = messages;

    // console.log({ fromApi });

    const apiMessages: any[] = fromApi.map((item: any) => {
      // console.log("message", item);

      let messageValue = `There was a transaction with ${
        item[7].amount / 1e9
      } SOL transferred.`;

      const date = new Date(item[4]);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

      return {
        type: "Message",
        now: date,
        text: messageValue as any,
        external: item[6],
        sendByUser: false,
      };
    });

    apiMessages.unshift({
      type: "Message",
      now: null,
      external: null as any,
      text: "You created a new alert!",
      sendByUser: true,
    });

    let lastDay = yesterday;
    let oneDay = 1000 * 60 * 60 * 24;
    let messagesWithTime = [];

    for (let msg of apiMessages) {
      const diff = msg.now ? msg.now.getTime() - lastDay.getTime() : 0;
      if (diff > oneDay) {
        messagesWithTime.push({
          type: "DateRow",
          value: msg.now,
        });
        lastDay = msg.now;
      }
      messagesWithTime.push(msg);
    }

    if (messagesWithTime.length == 1) {
      messagesWithTime.push({
        type: "InfoRow",
        info: "waiting for our first messages",
      });
    }

    setMessagesWithTime(messagesWithTime);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    enhanceData();
  }, [messages]);

  const delayedFetch = () => {
    setTimeout(() => {
      Client.getMessages(address, (response: any) => {
        response.json().then((response: any) => {
          setMessages(response);
        });
      });
      if (keepFetching) delayedFetch();
    }, 5000);
  };

  useEffect(() => {
    if (address) {
      Client.getMessages(address, (response: any) => {
        response.json().then((response: any) => {
          setMessages(response);
        });
      });
      // delayedFetch();
    }
  }, []);

  // useEffect(() => {
  //   console.log("Getting messages for", address);
  //   if (address) getMessages(address);
  // }, [address]);

  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (el.current != null) {
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [isLoading]);

  return (
    <>
      <Box
        p="3"
        // borderWidth="1px"
      >
        <Stack height={"50vh"} overflow="scroll" overflowX="hidden">
          {isLoading &&
            [0, 1, 2].map((v: number, index: number) => {
              return (
                <Message
                  key={`skeleton-message-${index}`}
                  skeleton={true}
                  sendByUser={v % 3 == 0}
                  text={makeid(15 + 10 * v)}
                  now={new Date()}
                />
              );
            })}

          {!isLoading &&
            messagesWithTime.map((data: any, index: number) => {
              if (data.type == "Message") {
                return (
                  <Message
                    key={`message-row-${index}`}
                    now={data.now}
                    text={data.text}
                    externalText={`Tx: ${data.external}`}
                    externalLink={`https://explorer.solana.com/tx/${data.external}?cluster=devnet`}
                    sendByUser={data.sendByUser}
                  />
                );
              }
              if (data.type == "DateRow") {
                return <DateRow key={`date-row-${index}`} now={data.value} />;
              }
              if (data.type == "InfoRow") {
                return <InfoRow key={`info-row-${index}`} info={data.info} />;
              }
            })}

          <div id={"el"} ref={el}></div>
        </Stack>

        <br />
        <br />
        <HStack width="100%">
          <Box
            bg="#eee"
            boxShadow="lg"
            as="button"
            alignItems="center"
            color="#555"
            minWidth="100px"
            padding="10px 20px 10px 20px"
            margin="3px"
            borderRadius="20px"
            onClick={() => {
              navigate(`/feeds?create=true`);
            }}
          >
            <Text fontSize="md">{"Create new feed"}</Text>
          </Box>
          {/*          <Box
            bg="#eee"
            boxShadow="lg"
            as="button"
            alignItems="center"
            color="#555"
            minWidth="100px"
            padding="10px 20px 10px 20px"
            margin="3px"
            borderRadius="20px"
          >
            <Stack>
              <Text fontSize="md">{"Stop these alerts"}</Text>
            </Stack>
          </Box>*/}
          <Box
            bg="#eee"
            boxShadow="lg"
            as="button"
            alignItems="center"
            color="#555"
            minWidth="100px"
            padding="10px 20px 10px 20px"
            margin="3px"
            borderRadius="20px"
            onClick={() => {
              navigate(`/edit/${address}`);
            }}
          >
            <Stack>
              <Text fontSize="md">{"Manage alerts"}</Text>
            </Stack>
          </Box>
        </HStack>
      </Box>
    </>
  );
};
