import { Box, Center } from "@chakra-ui/react";
import React from "react";

export const InfoRow = ({ info }: any) => {
  return (
    <Box>
      <Center mt={5} fontSize="sm" color="#999">
        {info}
      </Center>
    </Box>
  );
};
