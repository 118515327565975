import { Box, Icon, Skeleton, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { GoLinkExternal } from "react-icons/go";

export const Message = ({
  now,
  text,
  externalText,
  externalLink,
  sendByUser,
  skeleton = false,
}: {
  now?: any;
  text?: any;
  externalText?: any;
  externalLink?: any;
  sendByUser?: any;
  skeleton?: boolean;
}) => {
  const withPmAm =
    now &&
    now.toLocaleTimeString("en-US", {
      // en-US can be set to 'default' to use user's browser settings
      hour: "2-digit",
      minute: "2-digit",
    });

  return (
    <Box
      bg={!skeleton ? (sendByUser ? "#222" : "#EE5622") : "#e7ecf2"}
      as="span"
      alignItems="center"
      color="white"
      minWidth="100px"
      maxW="70%"
      alignSelf={sendByUser ? "flex-end" : "flex-start"}
      padding="15px"
      margin="3px"
      borderRadius="20px"
      boxShadow="lg"
    >
      <Stack minWidth={150}>
        <Skeleton isLoaded={!skeleton}>
          <Text>{text}</Text>
        </Skeleton>

        <Skeleton isLoaded={!skeleton}>
          {externalText && (
            <Text
              cursor="pointer"
              fontSize="xs"
              alignItems="center"
              style={{ display: "flex" }}
              onClick={() => {
                window.open(externalLink);
              }}
            >
              {externalText.length > 20
                ? externalText.slice(0, 20) + "..."
                : externalText}
              &nbsp;
              <Icon as={GoLinkExternal} />
            </Text>
          )}
        </Skeleton>

        <Skeleton isLoaded={!skeleton}>
          <Text fontSize="xs">{withPmAm}</Text>
        </Skeleton>
      </Stack>
    </Box>
  );
};
