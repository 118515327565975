import { EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsGear } from "react-icons/bs";
import { FaGreaterThan } from "react-icons/fa";
import { IoMdSave } from "react-icons/io";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Client } from "../services/server";
import { getTokenFromLocal } from "../utils/utils";

import { PageTitle } from "../components/PageTitle";
import { UserHeader } from "../components/UserHeader";

export const Main = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const navigate = useNavigate();

  const { address } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inCreateMode = pathname.startsWith("/create");
  const createName = searchParams.get("name");

  const [inputs, setInputs] = useState({
    address: address,
    ruleName: createName,
    email: null,
    callbackUrl: null,
    txCountValue: 0,
    windowMinutesValue: 0,
    solThreshold: 0,
    //
    freqOn: false,
    amountOn: false,
    emailOn: false,
    webHooktOn: false,

    //
    emailFrequency: "",

    //
    groupedOptions: [],
    currentOption: {
      value: "",
      label: "...",
    },

    // ui
    isLoading: true,
  });

  useEffect(() => {
    if (inCreateMode) {
      setIsLoading(false);
      return;
    }

    if (inputs.address !== "") {
      Client.getData(inputs.address, (response: any) => {
        response.json().then((response: any) => {
          setData(response);
        });
      });
    }
  }, [inputs.address]);

  useEffect(() => {
    if (inputs.currentOption && inputs.currentOption.value !== "") {
      handleAddressSelect([null, inputs.currentOption]);
    }
  }, [inputs.currentOption]);

  useEffect(() => {
    if (inputs.solThreshold == 0) {
      handleAmountOnChange(false);
    }
    if (inputs.solThreshold > 0) {
      handleAmountOnChange(true);
    }
  }, [inputs.solThreshold]);

  const handleAddressSelect = (value: any) => {
    setIsLoading(true);
    if (value.length < 2) return;
    const selected = value[1];
    const addr = selected.value.replace(/[^a-z0-9]/gi, "");
    // console.log(selected);
    setInputs((previousInputs) => ({
      ...previousInputs,
      address: addr,
      currentOption: selected,
    }));
  };

  const handleSetSelected = (event: any) => {
    setInputs((previousInputs) => ({
      ...previousInputs,
      emailFrequency: event.target.value,
    }));
  };

  const handleNameChange = (value: any) =>
    setInputs((previousInputs) => ({
      ...previousInputs,
      ruleName: value,
    }));

  const handleSolThresholdChange = (value: any) =>
    setInputs((previousInputs) => ({
      ...previousInputs,
      solThreshold: value,
    }));

  const handleAmountOnChange = (value: any) =>
    setInputs((previousInputs) => ({
      ...previousInputs,
      amountOn: value,
    }));

  const setIsLoading = (value: boolean) =>
    setInputs((previousInputs) => ({
      ...previousInputs,
      isLoading: value,
    }));

  const setData = async (response: any) => {
    setIsLoading(true);

    let resp = response[0];

    if (!resp) return;

    let [address, id, data] = resp;

    const newData: any = {
      address: inputs.address,
      ruleName: data.name,
      txCountValue: data.rules["tx::freq"].values.txs,
      windowMinutesValue: data.rules["tx::freq"].values.mins,
      solThreshold: data.rules["tx::sol"].values.val,
      freqOn: data.rules["tx::sol"].live,
      amountOn: data.rules["tx::freq"].live,
      emailFrequency: data.emailFrequency,
      groupedOptions: inputs.groupedOptions,
      currentOption: inputs.currentOption,
      isLoading: false,
    };

    setInputs(newData);
    setIsLoading(false);
  };

  const handleSave = () => {
    setIsSaving(true);

    const token = getTokenFromLocal();

    if (!token) {
      // console.log("We need to sign in. We dont have a token");
      return;
    }

    const mybody: any = {
      address: inputs.address,
      rule: {
        name: inputs.ruleName,
        rules: {
          "tx::freq": {
            live: inputs.freqOn,
            values: {
              txs: inputs.txCountValue,
              mins: inputs.windowMinutesValue,
            },
          },
          "tx::sol": {
            live: inputs.amountOn,
            values: { val: inputs.solThreshold },
          },
        },
        emailFrequency: inputs.emailFrequency,
      },
    };

    Client.updateRule(mybody, (response: any) => {
      response.text().then((response: any) => {
        setIsSaving(false);
      });
    });
  };

  return (
    <Stack minW="60vw" maxW={600}>
      <PageTitle title={"Edit"} icon={<BsGear />} showBack={true} />

      <Box p="5">
        <UserHeader
          name={inputs.ruleName}
          address={inputs.address}
          onNameChange={handleNameChange}
        />
        <Spacer mt={4} mb={2} />
        <Skeleton isLoaded={!inputs.isLoading}>
          <Box padding={5} borderWidth="1px">
            <Stack>
              <HStack mb={4} mt={4}>
                <HStack width="100%">
                  <FaGreaterThan />
                  <Text fontSize="md" fontWeight="bold">
                    Transfer Amount Exceeds
                  </Text>
                </HStack>
                <Switch
                  colorScheme="brand"
                  id="email-alerts"
                  isChecked={inputs.amountOn}
                  onChange={() => handleAmountOnChange(!inputs.amountOn)}
                />
              </HStack>
              <Text size="xs">
                This will be triggered when any transaction results in a delta
                to the address above that is greater than the number specified
              </Text>

              <Text mb="1" textAlign="end">
                {inputs.solThreshold} SOL
              </Text>
            </Stack>
            <Slider
              colorScheme="brand"
              flex="1"
              max={20}
              focusThumbOnChange={false}
              value={inputs.solThreshold}
              onChange={handleSolThresholdChange}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb
                fontSize="sm"
                boxSize="32px"
                children={inputs.solThreshold}
              />
            </Slider>
          </Box>
        </Skeleton>
        <br />

        <Skeleton isLoaded={!inputs.isLoading}>
          <Box padding={5} borderWidth="1px">
            <Stack>
              <HStack mb={4} mt={4}>
                <HStack width="100%">
                  <EmailIcon />
                  <Text fontSize="md" fontWeight="bold">
                    Email Settings
                  </Text>
                </HStack>
              </HStack>
              <Text size="xs">
                Specify the frequency that you'd like to receive email
                notifications for this feed.
              </Text>
            </Stack>

            <Stack mt={10}>
              <Text fontSize="md" fontWeight="semibold">
                Send an email after...
              </Text>
              <CheckboxGroup
                colorScheme="brand"
                value={[inputs.emailFrequency]}
              >
                <Stack mt={1} spacing={1}>
                  <Checkbox value="each" onChange={handleSetSelected}>
                    Each event
                  </Checkbox>
                  <Checkbox value="hourly" onChange={handleSetSelected}>
                    Each hour
                  </Checkbox>
                  <Checkbox value="daily" onChange={handleSetSelected}>
                    Each Day
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            </Stack>
          </Box>
        </Skeleton>
      </Box>
      <Box px={5}>
        {inCreateMode && (
          <HStack spacing={4}>
            <Button
              leftIcon={isSaving ? <Spinner /> : <IoMdSave />}
              colorScheme="brand"
              variant="solid"
              onClick={() => {
                handleSave();
                navigate(`/feeds/${inputs.address}`);
              }}
            >
              Finish
            </Button>
          </HStack>
        )}
        {!inCreateMode && (
          <HStack spacing={4}>
            <Button
              leftIcon={isSaving ? <Spinner /> : <IoMdSave />}
              colorScheme="brand"
              variant="solid"
              onClick={() => {
                handleSave();
                navigate(`/feeds/${inputs.address}`);
              }}
            >
              Save
            </Button>
          </HStack>
        )}
      </Box>
    </Stack>
  );
};
