import { Center, ChakraProvider, extendTheme } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { install } from "resize-observer";
import { BetaBar } from "./components/BetaBar";
import { NavMenu } from "./components/NavMenu";
import { API_BASE_URL } from "./utils/constants";
import { getTokenFromLocal } from "./utils/utils";

import { Home } from "./pages/Home";
import { Main } from "./pages/Main";
import { Messages } from "./pages/Messages";
import { Profile } from "./pages/Profile";

import "./App.css";

install();

const theme = extendTheme({
  colors: {
    brand: {
      500: "#EE5622", // you need this
    },
  },
});

const App = () => {
  useEffect(() => {
    let token = getTokenFromLocal();
    console.log({ token });
    if (!token) {
      console.log("Redirect to login from top level");
      window.location.href = `${API_BASE_URL}/privatepage`;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const onopen: any = (res: any) => {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      };
    };
    fetchData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="feeds" element={<Messages />} />
            <Route path="profile" element={<Profile />} />

            {/* use params to allow user to navigate to modals and content specific page*/}
            <Route path="feeds/:address" element={<Messages />} />
            <Route path="create/:address" element={<Main />} />
            <Route path="edit/:address" element={<Main />} />

            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

// wrapper for routes above
function Layout() {
  return (
    <>
      <BetaBar />
      <NavMenu />
      <Center>
        <Outlet />
      </Center>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
