import {
  Box,
  Circle,
  // Select,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Blockies from "react-blockies";
import { BiMessageAltDetail } from "react-icons/bi";
import { FiEdit, FiTrash } from "react-icons/fi";
import { RiMore2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export const SenderRow = ({
  index,
  sender,
  deleteConversation,
  getRecents,
  showMenu = true,
}: any) => {
  const navigate = useNavigate();
  return (
    <Box
      as="div"
      key={`sender-${index}`}
      width="100%"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      py="12px"
      px="16px"
      margin="6px"
    >
      <HStack>
        <HStack
          onClick={() => {
            navigate(`/feeds/${sender.address}`);
          }}
          width="100%"
        >
          <Circle size="40px" color="white" overflow="hidden">
            <Blockies
              seed={sender.address ? sender.address : "blank"}
              size={15}
              scale={4}
              className="identicon"
            />
          </Circle>

          <Stack>
            <Box
              as={Text}
              fontSize="lg"
              alignItems="center"
              // style={{ display: "flex" }}
            >
              {sender.name}
            </Box>

            <Box
              as={Text}
              marginTop={"-4px !important"}
              fontSize="xs"
              alignItems="center"
              // style={{ display: "flex" }}
            >
              {sender.lastSendTime}
            </Box>
          </Stack>
        </HStack>
        {showMenu && (
          <HStack justifyContent="end">
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<RiMore2Fill />}
                variant="outline"
              />
              <MenuList>
                <MenuItem
                  icon={<BiMessageAltDetail />}
                  onClick={() => {
                    navigate(`/feeds/${sender.address}`);
                  }}
                >
                  Open
                </MenuItem>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    navigate(`/edit/${sender.address}`);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  color="red"
                  icon={<FiTrash color="red" />}
                  onClick={() => {
                    // console.log("menu click");
                    deleteConversation(sender.address);
                    getRecents();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        )}
      </HStack>
    </Box>
  );
};
