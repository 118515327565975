import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { BiMessageAltDetail } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiHomeSmile2Line, RiLogoutBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getTokenFromLocal } from "../utils/utils";
import { DOMAIN } from "../utils/constants";

export function NavMenu() {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<GiHamburgerMenu />}
        variant="outline"
        margin="20px"
      />
      <MenuList>
        <Link to="/">
          <MenuItem icon={<RiHomeSmile2Line />}>Home</MenuItem>
        </Link>
        <Link to="/feeds">
          <MenuItem icon={<BiMessageAltDetail />}>Feeds</MenuItem>
        </Link>
        <Link to="/profile">
          <MenuItem icon={<CgProfile />}>Profile</MenuItem>
        </Link>
        <>
          <MenuItem
            color="red"
            icon={<RiLogoutBoxLine />}
            onClick={() => {
              const token = getTokenFromLocal();
              const options = {
                method: "GET",
                headers: {
                  token: token,
                  "Content-Type": "application/json",
                },
              };

              document.cookie = `token=;max-age=0;path=/;domain=${DOMAIN}`;
              setTimeout(() => {
                window.location.replace("https://solanitor.com");
              }, 1000);
            }}
          >
            Log out
          </MenuItem>
        </>
      </MenuList>
    </Menu>
  );
}
