import {
  Badge,
  Box,
  Button,
  Center,
  Circle,
  Divider,
  HStack,
  Skeleton,
  SkeletonCircle,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Blockies from "react-blockies";
import { BiPurchaseTag } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";

import { Client } from "../services/server";
import { getTokenFromLocal, parseJwt } from "../utils/utils";

import { PageTitle } from "../components/PageTitle";

export const Profile = () => {
  const getUser = () => {
    const token = getTokenFromLocal();
    console.log("Profile");
    const data = parseJwt(token);
    return data;
  };

  const user = getUser();

  // const skeleton = false;
  const sendByUser = true;
  const text = "we doing it";

  const [userInfo, setUserInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    Client.getUserInformation((response: any) => {
      response.json().then((parsed: any) => {
        console.log(parsed);

        const results = {
          numberOfTriggers: parsed.information[0],
          numberOfAddresses: parsed.information[1],
          isSubscribed: parsed.subscribed == "subscribed",
        };

        setUserInfo(results);
      });
    });
  }, []);

  useEffect(() => {
    const numberOfKeys = Object.keys(userInfo).length;
    if (numberOfKeys > 0) {
      setIsLoading(false);
    }
  }, [userInfo]);

  const statusBadge =
    userInfo && userInfo.isSubscribed ? (
      <Badge color="green">premium</Badge>
    ) : (
      <Badge color="grey">basic</Badge>
    );

  return (
    <Stack minW="60vw" maxW={600}>
      <PageTitle title={"Profile"} icon={<CgProfile />} />

      <Box p="5" borderWidth="1px">
        <Stack minWidth={150}>
          <Center>
            {isLoading ? (
              <SkeletonCircle size="100px"></SkeletonCircle>
            ) : (
              <Circle
                size="100px"
                color="white"
                overflow="hidden"
                position="relative"
              >
                <Blockies
                  seed={user.displayName}
                  size={15}
                  scale={7}
                  className="identicon"
                />
              </Circle>
            )}
          </Center>
          <Center>
            <Skeleton isLoaded={!isLoading}>
              <Text mt={2} mb={2} fontSize="xl" fontWeight="bold" color="#333">
                {user.displayName}
              </Text>
            </Skeleton>
          </Center>

          <Text fontSize="xs" fontWeight="bold" color="#777">
            User Information
          </Text>
          {[
            //
            ["Joined:", "August 31st 2022"],
            ["Status:", statusBadge],
            ["Email:", user.emails[0]],
          ].map(([key, value]: any, index: number) => {
            return (
              <Skeleton isLoaded={!isLoading} key={`profile-info-row-${index}`}>
                <HStack>
                  <Text fontSize="sm" fontWeight="bold" color="#333">
                    {key}
                  </Text>
                  <Text fontSize="sm">{value}</Text>
                </HStack>
              </Skeleton>
            );
          })}
          <br />
          <Text fontSize="xs" fontWeight="bold" color="#777">
            Statistics
          </Text>
          <StatGroup>
            <Stat>
              <StatLabel>Events Captured</StatLabel>

              <Skeleton isLoaded={!isLoading}>
                <StatNumber>
                  {userInfo && userInfo.numberOfTriggers
                    ? userInfo.numberOfTriggers
                    : 0}
                </StatNumber>
              </Skeleton>
            </Stat>

            <Stat>
              <StatLabel>Addresses Watching</StatLabel>

              <Skeleton isLoaded={!isLoading}>
                <StatNumber>
                  {userInfo && userInfo.numberOfAddresses
                    ? userInfo.numberOfAddresses
                    : 0}
                </StatNumber>
              </Skeleton>
            </Stat>
          </StatGroup>

          <br />
          <Divider />

          <br />
          <Box
            disabled={true}
            as={Button}
            aria-label="Upgrade"
            rightIcon={<BiPurchaseTag />}
            colorScheme="brand"
          >
            Upgrade (coming soon)
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
