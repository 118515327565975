import { Stack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { SenderList } from "../components/SenderList";

export const Messages = () => {
  const navigate = useNavigate();
  return (
    <Stack minW="60vw" maxW={600}>
      <SenderList />
    </Stack>
  );
};
