import { Box, Center } from "@chakra-ui/react";
import React from "react";

export const DateRow = ({ now }: any) => {
  const dateStr = now.toLocaleDateString();

  return (
    <Box>
      <Center fontWeight="bold" fontSize="xs" color="#777">
        {dateStr}
      </Center>
    </Box>
  );
};
