import { Badge, Box, Center, Text } from "@chakra-ui/react";

export function BetaBar() {
  return (
    <Box width="100%" bgColor="brand.500" height="30px">
      <Center>
        <Badge mt="1">
          <Text
            fontSize="sm"
            fontWeight="bold"
            color="brand.500"
          >
            Beta Mode
          </Text>
        </Badge>
      </Center>
    </Box>
  );
}
