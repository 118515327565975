import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Circle,
  Editable,
  EditableInput,
  EditablePreview,
  // Select,
  HStack,
  IconButton,
  Stack,
  Text,
  useEditableControls,
} from "@chakra-ui/react";
import React from "react";
import Blockies from "react-blockies";
import { HiOutlineClipboardCopy } from "react-icons/hi";

export const UserHeader = ({ address, name, onNameChange }: any) => {
  // const [currentName, setCurrentName] = useState(name);

  // useEffect(() => {
  //   setCurrentName(name);
  // }, [name]);

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      //   <ButtonGroup size="sm">
      //     <IconButton
      //       aria-label="x"
      //       icon={<CgProfile />}
      //       {...getSubmitButtonProps()}
      //     />
      //     <IconButton
      //       aria-label="x"
      //       icon={<CgProfile />}
      //       {...getCancelButtonProps()}
      //     />
      //   </ButtonGroup>

      <>
        <Circle mt={"11px"} size="10px" backgroundColor="brand.500" />
        {/*        <IconButton
          marginTop={1}
          marginLeft={0}
          size="xs"
          variant="outline"
          aria-label="copy address to clipboard"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          marginTop={1}
          marginLeft={1}
          size="xs"
          variant="outline"
          aria-label="copy address to clipboard"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />*/}
      </>
    ) : (
      <>
        <IconButton
          marginTop={1}
          marginLeft={4}
          size="xs"
          variant="outline"
          aria-label="copy address to clipboard"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </>
    );
  }

  return (
    <HStack>
      <Circle size="40px" color="white" overflow="hidden">
        <Blockies
          seed={address ? address : name ? name : ""}
          size={15}
          scale={4}
          className="identicon"
        />
      </Circle>

      <Stack>
        <Box color="#555">
          <Stack>
            <HStack>
              {name && (
                <Editable
                  display="flex"
                  mb={address && -3}
                  // textAlign="center"
                  value={name}
                  fontWeight="semibold"
                  fontSize="lg"
                  isPreviewFocusable={false}
                  selectAllOnFocus={false}
                >
                  <EditablePreview />
                  <Box
                    size={name && name.length}
                    // border={"none"}
                    // outlineWidth={0}
                    boxShadow={"none !important"}
                    id="inner-input"
                    as={EditableInput}
                    onInput={(e: any) => {
                      // console.log("should update name", e.target.value);
                      onNameChange(e.target.value);
                    }}
                    // auto grow/shrink of input
                    // onInput={(e)=>{
                    //   let tar = e.target as any
                    //   console.log(tar.size)
                    //   tar.size = tar.value.length
                    // }}
                    // oninput={'this.size = this.value.length' as any}
                  />
                  {onNameChange && <EditableControls />}
                </Editable>
              )}

              {/*              <Text mb={address && -3} fontSize="lg" fontWeight="semibold">
                {name && `${name}`}
              </Text>
*/}
              {/*              <IconButton
                size="xs"
                variant="outline"
                aria-label="copy address to clipboard"
                icon={<FiEdit />}
                onClick={() => {
                  if (address) navigator.clipboard.writeText(address);
                }}
              />*/}
            </HStack>

            {address && (
              <HStack>
                <Text fontSize="xs">
                  {address && `${address.slice(0, 20)}...`}
                </Text>

                <IconButton
                  size="xs"
                  variant="outline"
                  aria-label="copy address to clipboard"
                  icon={<HiOutlineClipboardCopy />}
                  onClick={() => {
                    if (address) navigator.clipboard.writeText(address);
                  }}
                />
              </HStack>
            )}
          </Stack>
        </Box>
      </Stack>
    </HStack>
  );
};
