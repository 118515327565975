import {
  Box,
  Center,
  Code,
  HStack,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { BiBarChart, BiLineChart } from "react-icons/bi";
import { RiHomeSmile2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { Client } from "../services/server";
import { addHours, getTokenFromLocal, parseJwt } from "../utils/utils";

import { PageTitle } from "../components/PageTitle";
import { SenderRow } from "../components/SenderRow";
import { UserHeader } from "../components/UserHeader";

export const Home = () => {
  const [user, setUser] = useState<any>(null);
  const [activityData, setActivityData] = useState<any>([]);
  const [showAreaChart, setShowAreaChart] = useState<boolean>(false);
  const isLoading = false;
  const utcHourOffset = new Date().getTimezoneOffset() / 60;
  const [senders, setSenders] = useState<any>([]);
  const navigate = useNavigate();

  function formatAMPM(hours: any) {
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var strTime = hours + " " + ampm;
    return strTime;
  }

  const data: any = {
    options: {
      grid: {
        show: false,
      },
      plotOptions: {
        bar: {
          barHeight: "80%",
          borderRadius: 8,
        },
      },
      yaxis: {
        show: false,
        max: Math.max(...activityData.map((item: any) => item[1])) + 1,
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        categories: !activityData
          ? 0
          : // : activityData.map((item: any) => parseInt(item[0].slice(-2)) - utcHourOffset ),
            activityData.map((item: any) => {
              const correct24Hour =
                (parseInt(item[0].slice(-2)) + 24 - utcHourOffset) % 24;
              return correct24Hour // formatAMPM(correct24Hour);
            }),
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
      chart: {
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 50,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 50,
          },
        },

        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
          autoSelected: "zoom",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        colors: ["#EE5622"],
        gradient: {
          type: "vertical",
          color: "#555",
          opacityFrom: 0.9,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      stroke: {
        show: false,
      },
    },
    series: [
      {
        name: "series-1",
        data: !activityData ? 0 : activityData.map((item: any) => item[1]),
      },
    ],
  };

  useEffect(() => {
    const token = getTokenFromLocal();
    console.log("Home", token);
    if (token) {
      setUser(parseJwt(token));
    }
  }, []);

  useEffect(() => {
    if (user) {
      Client.getRecents((response: any) => {
        response.json().then((parsed: any) => {
          let items = parsed.map((data: any) => {
            let [address, name, unreadCount, lastSendTime] = data;
            name = name ? name : address.slice(0, 10) + "...";
            const storedTime = lastSendTime;
            // fix bad times
            if (!storedTime) {
              return {
                address,
                name,
                unreadCount,
                lastSendTime: `waiting...`,
              };
            } else {
              const mydate = new Date(storedTime);
              const dateStr = mydate.toLocaleDateString();
              const withPmAm = mydate.toLocaleTimeString("en-US", {
                // en-US can be set to 'default' to use user's browser settings
                hour: "2-digit",
                minute: "2-digit",
              });
              return {
                address,
                name,
                unreadCount,
                lastSendTime: `${dateStr} ${withPmAm}`,
              };
            }
          });
          setSenders(items);
        });
      });

      Client.getActivityData((response: any) => {
        response.json().then((parsed: any) => {
          const dataMap: any = {};
          for (let item of parsed) {
            const date = new Date(item[0]);
            dataMap[date.toISOString().slice(0, 13)] = item[1];
          }
          const data = [];
          const hours = 12;
          const oldest = new Date(Date.now() - hours * 3600 * 1000);
          var start = oldest;
          var end = new Date(Date.now() + 1 * 3600 * 1000);
          var loop = new Date(start);
          while (loop <= end) {
            const date = loop.toISOString().slice(0, 13);
            const val = dataMap[date] ? parseInt(dataMap[date]) : 0;
            data.push([date, val]);
            var newDate = addHours(1, loop);
            loop = new Date(newDate);
          }
          setActivityData(data);
        });
      });
    }
  }, [user]);

  return (
    <Stack minW="60vw" maxW={600}>
      <PageTitle title={"Home"} icon={<RiHomeSmile2Line />} />
      <Skeleton isLoaded={user}>
        <Box>
          <Stack minWidth={150}>
            <UserHeader name={user ? user.displayName : ""} address={null} />
            <HStack>
              <Stack width="100%">
                <Text pt={8} fontSize="xs" fontWeight="bold" color="#777">
                  Recent Chart
                </Text>
                <Text mt={"0px !important"} fontSize="xs" color="#999">
                  Events captured
                </Text>
              </Stack>

              <Stack pt={8} pr={2}>
                <IconButton
                  size="sm"
                  aria-label="Toggle light dark mode"
                  onClick={() => setShowAreaChart(!showAreaChart)}
                  variant="outline"
                  icon={showAreaChart ? <BiLineChart /> : <BiBarChart />}
                />
              </Stack>
            </HStack>

            {showAreaChart ? (
              <Chart
                options={data.options}
                series={data.series}
                type={"bar"}
                width="100%"
                height={300}
              />
            ) : (
              <Chart
                options={data.options}
                series={data.series}
                type={"area"}
                width="100%"
                height={300}
              />
            )}
            {false && (
              <>
                <br />
                <Code>
                  <pre>{JSON.stringify(activityData, null, 2)}</pre>
                </Code>
              </>
            )}
            <br />
            <Text pt={3} pb={3} fontSize="xs" fontWeight="bold" color="#777">
              Most Recent
            </Text>

            {senders.length == 0 && (
              <>
                {[0, 1].map((sender: any, index: number) => {
                  return (
                    <Box
                      key={`blanksender${index}`}
                      width="100%"
                      height="72px"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      padding="10px"
                      margin="3px"
                    >
                      <HStack>
                        <SkeletonCircle size="40px" />

                        <Stack style={{ flexGrow: 1, width: 400 }}>
                          <Skeleton isLoaded={false}>
                            <div
                              style={{
                                width: "max-content",
                              }}
                            >
                              {sender}
                            </div>
                          </Skeleton>
                          <Skeleton isLoaded={false}>
                            <div
                              style={{
                                width: "max-content",
                                height: "8px",
                              }}
                            >
                              {sender}
                            </div>
                          </Skeleton>
                        </Stack>
                      </HStack>
                    </Box>
                  );
                })}
              </>
            )}

            {senders.slice(0, 2).map((sender: any, index: number) => {
              return (
                <SenderRow
                  key={`sender-row-${index}`}
                  index={`home-${index}`}
                  sender={sender}
                  deleteConversation={null}
                  getRecents={null}
                  showMenu={false}
                />
              );
            })}
            <Center>
              <Text
                fontSize="sm"
                onClick={() => {
                  navigate(`/feeds`);
                }}
              >
                see all
              </Text>
            </Center>
          </Stack>
        </Box>
      </Skeleton>
    </Stack>
  );
};
