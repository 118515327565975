import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import { IoMdCreate } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export function AddNewAddressFlow({ startWithFlowOpen }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [newAddress, setNewAddress] = useState<string>("");
  const handleAddressUpdate = (event: any) => setNewAddress(event.target.value);

  const [newName, setNewName] = useState<string>("");
  const handleNameUpdate = (event: any) => setNewName(event.target.value);

  useEffect(() => {
    if (startWithFlowOpen && !isOpen) onOpen();
  }, [startWithFlowOpen]);

  return (
    <>
      <Center>
        <Box
          as={Button}
          aria-label="Add"
          rightIcon={<IoMdCreate />}
          colorScheme="brand"
          maxWidth="200px"
          onClick={onOpen}
          variant="outline"
        >
          Add Feed
        </Box>
      </Center>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="My Wallet"
                focusBorderColor={
                  newAddress.length == 44 ? "green.500" : "brand.500"
                }
                borderColor={newAddress.length == 44 ? "green.500" : ""}
                value={newName}
                onChange={handleNameUpdate}
              />
              <Spacer mt={6} />
              <FormLabel>Address</FormLabel>
              <Input
                focusBorderColor={
                  newAddress.length == 44 ? "green.500" : "brand.500"
                }
                borderColor={newAddress.length == 44 ? "green.500" : ""}
                placeholder="AbcdEF..."
                value={newAddress}
                onChange={handleAddressUpdate}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Box
              as={Button}
              aria-label="Add"
              rightIcon={<GoArrowRight />}
              disabled={newAddress.length != 44}
              colorScheme={"brand"}
              onClick={() => {
                navigate(`/create/${newAddress}?name=${newName}`);
              }}
            >
              Next
            </Box>

            {/*<Button onClick={onClose}>Cancel</Button>*/}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
