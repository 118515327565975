import {
  Box,
  Center,

  // Select,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export const PageTitle = ({ title, icon, showBack }: any) => {
  const navigate = useNavigate();

  return (
    <HStack mb={5}>
      {showBack && (
        <Box width={"60px !important"}>
          <AiOutlineArrowLeft
            style={{ marginLeft: 30 }}
            onClick={() => {
              // navigate(`/messages/${address}`);
              navigate(-1);
            }}
          />
        </Box>
      )}

      <Center width={"100%"} style={{ margin: "0px" }}>
        <HStack>
          <Text fontSize={20} fontWeight="bold" color="#555">
            {icon}
          </Text>
          <Text fontSize="xl" fontWeight="normal" color="#555">
            {title}
          </Text>
        </HStack>
      </Center>
      {showBack && <div style={{ width: 60 }} />}
    </HStack>
  );
};
