import { getTokenFromLocal } from "../utils/utils";
import { API_BASE_URL } from "../utils/constants";

export class Client {
  static getMessages = (address: string, callback: any) => {
    const token = getTokenFromLocal();

    if (!address) return;

    const data = { address: address, timestamp: 0 };

    const options = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    // console.log(options);

    fetch(`${API_BASE_URL}/messages`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static getActivityData = (callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "GET",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };

    fetch(`${API_BASE_URL}/activity`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static getRecents = (callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "GET",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      // credentials: 'include'
    };

    fetch(`${API_BASE_URL}/recent`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static getUserInformation = (callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "GET",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };

    fetch(`${API_BASE_URL}/profile`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static getData = (address: any, callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address }),
    };

    fetch(`${API_BASE_URL}/rules`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static deleteConversation = (address: any, callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address }),
    };

    fetch(`${API_BASE_URL}/remove`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };

  static updateRule = (body: any, callback: any) => {
    const token = getTokenFromLocal();

    const options: any = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    fetch(`${API_BASE_URL}/manage`, options)
      .then(callback)
      .catch((err) => console.error(err));
  };
}
